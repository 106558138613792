import React from "react";
import ReactDOM from "react-dom/client";
import { doc, getFirestore } from "firebase/firestore";
import {
	FirebaseAppProvider,
	FirestoreProvider,
	useFirestoreDocData,
	useFirestore,
	useFirebaseApp,
} from "reactfire";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./styles/index.css";
const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const firebaseConfig = {
	apiKey: "AIzaSyANLv4wEOAno5TjdwC6R95WNYavxuFryrM",
	authDomain: "investincode.firebaseapp.com",
	projectId: "investincode",
	storageBucket: "investincode.appspot.com",
	messagingSenderId: "40671492766",
	appId: "1:40671492766:web:b8a282ba930cb6a6d3198f",
	measurementId: "G-9CKJXS0E27",
};

// function BurritoTaste() {
// 	// access the Firestore library
// 	const burritoRef = doc(useFirestore(), "tryreactfire", "burrito");

// 	// subscribe to a document for realtime updates. just one line!
// 	const { status, data } = useFirestoreDocData(burritoRef);

// 	// check the loading status
// 	if (status === "loading") {
// 		return <p>Fetching burrito flavor...</p>;
// 	}

// 	return <p>The burrito is {data.yummy ? "good" : "bad"}!</p>;
// }

// function App() {
// 	const firestoreInstance = getFirestore(useFirebaseApp());
// 	return (
// 		<React.StrictMode>
// 			<BrowserRouter>

// 				{/* <FirestoreProvider sdk={firestoreInstance}>
// 					<h1>🌯</h1>
// 					<BurritoTaste />
// 				</FirestoreProvider> */}
// 			</BrowserRouter>
// 		</React.StrictMode>
// 	);
// }

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<FirebaseAppProvider firebaseConfig={firebaseConfig}>
				<App />
			</FirebaseAppProvider>
		</BrowserRouter>
	</React.StrictMode>
);
